$border: 2px;
$sidePad: 10px;

.c-form {
  &__element {
    margin-bottom: 20px;
  }

  &__label {
    display: block;
    margin: 0 0 5px calc(#{$border} + #{$sidePad});
    font-size: 14px;
    line-height: 19px;
    color: var(--color-mid-gray);

    &--radio {
      margin-left: 0;
    }
  }

  &__input-wrapper {
    background: var(--color-light-gray);
    border: $border solid #dde4e9;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    &--error {
      border-color: #d22f19;
    }

    svg {
      margin: 0 10px;
      position: absolute;
      right: 0;

      path {
        fill: #216ef2 !important;
      }
    }
  }

  &__error-text {
    margin: 5px 0 0 $sidePad;
    font-weight: bold;
    font-size: 14px;
  }

  &__input {
    border: 0;
    background: 0;
    width: 100%;
    font-family: var(--font-primary);
    font-weight: 600;
    border-radius: 8px;
    font-size: 16px;
    line-height: 24px;
    padding: 5px $sidePad;
    color: var(--color-tertiary);
    outline-color: var(--color-primary);

    &-icon {
      cursor: pointer;
    }

    &--locked {
      padding-right: 30px;
    }

    &--error {
      outline-color: #d22f19;
    }

    &-password {
      padding-right: 40px;
    }
  }

  &__radio-wrapper {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
  }

  &__radio-wrapper-column {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }

  &__radio-element {
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;

    input {
      margin: 0 5px 0 0;
    }
  }

  &__button {
    width: 100%;
    margin-top: 10px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-resize {
  resize: none;
}

.preview-box {
  background-color: #f2f2f2;
}

.c-pwv {
  //Password Validator
  position: absolute;
  font-size: 13px;
  left: calc(-50%);
  transform: translateY(-45px);
  opacity: 0;
  z-index: -2;
  transition: all ease 0.3s;

  &--active {
    opacity: 1;
    z-index: 2;
  }

  &__wrapper {
    background: white;
    padding: 20px;
    border: 1px solid #dde4e9;
    border-radius: 8px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  }

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-top: 1px solid #dde4e9;
    border-right: 1px solid #dde4e9;
    box-sizing: border-box;
    border-radius: 2px;
    transform: translateY(-50%) rotate(45deg);
    position: absolute;
    right: 0;
    top: 50%;
    right: -10px;
  }

  ul {
    list-style: none;
    margin: 0 0 0 -40px;
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h5 {
    color: var(--color-primary);
    font-weight: bold;
    margin-bottom: 10px;
  }

  &--validated {
    .c-pwv__circle {
      background-color: #78d278;
    }
  }

  &__circle {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--color-error);
    margin-right: 10px;
  }
}
