/*
/dev-site/static/media/ -- use for storybook. Doesn't work as it throws a CORS issue which is odd as it is on the same server
../../assets/fonts/ -- use for app
*/

@font-face {
  font-family: 'icomoon';
  src: url('../../assets/fonts/icomoon.eot');
  src: url('../../assets/fonts/icomoon.eot#iefix') format('embedded-opentype'),
    url('../../assets/fonts/icomoon.woff') format('woff'), url('../../assets/fonts/icomoon.ttf') format('truetype'),
    url('../../assets/fonts/icomoon.svg#opusicons') format('svg');
  font-weight: normal;
  font-style: normal;
}
